/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Custom template
    'page_template_template_custom': {
      init: function() {

        // JavaScript to be fired on the custom template page
        $(".acf-field-repeater .acf-row .acf-fields")
        .prepend("<h3>Attendee Details</h3>")
        .append("<div class='acf-field'><a class='acf-button button button-primary' href='#' data-event='attendee-remove-row'><i class='fa fa-fw fa-times'></i> Remove this Attendee</a></div>");

        var deactivate = function() {
          if ($("body.sage").length) return;
          $("input[type='submit'].acf-button.button-primary").prop("disabled", true);
        };
        var activate = function() {
          if ($("body.sage").length) return;
          $("input[type='submit'].acf-button.button-primary").prop("disabled", false);
        };

        var $attendees = 0;
        if (!$attendees)
          deactivate();

        $(".acf-field-repeater").on("click", "a[data-event='attendee-remove-row']", function(e) {
          e.preventDefault();
          e.stopPropagation();
          $(this).closest(".acf-row").find(".remove .-minus[data-event='remove-row']").trigger("click").trigger("click");
          $attendees--;
          if (!$attendees) {
            deactivate();
          }
        });

        $(".acf-actions .acf-button.button-primary[data-event='add-row']")
          .on("click", function() {
            $attendees++;
            activate();
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
